import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import Title from "../../components/title"
// import Hero from "../../blocks/Hero"
import { FixedBanner } from "../../blocks/Banner"
import TextWithImage from "../../blocks/TextWithImage"

const Blockchain = () => (
  <Layout>
    {/* <Hero
      data={{
        title: "Paybase for Cryptocurrency",
        copy:
          '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur non lorem ultrices lobortis. Donec in mauris sed eros volutpat tempor eget nec purus. Duis sagittis tempor volutpat. Pellentesque ornare justo in felis efficitur, non semper nisi imperdiet. Morbi rutrum tellus nisl."',
        companyLogo: {
          src: "/who-is-it-for/marketplace/airbnb.png",
          alt: "John Smith, Product manager at Fast banana",
          copy: "John Smith, Product manager at Fast banana",
        },
        image: {
          src: "/who-is-it-for/blockchain/img1.png",
          alt: "placeholder image",
        },
        color1: "rose",
        color2: "darkgreen",
        color3: "blue",
      }}
    /> */}
    <Helmet>
      <title>Paybase - Blockchain</title>
      <meta property="og:title" content="Paybase - Blockchain" />
    </Helmet>
    <TextWithImage
      data={{
        title: "Paybase for Blockchain Businesses",
        content:
          "Gain fiat-crypto interoperability, allowing users to purchase cryptocurrency with fiat and redeem fiat they have gained through selling cryptocurrency. Paybase offers full regulatory cover for fiat payments, including full funds custody and real-time payments both in and out.",
        image: {
          src: "/who-is-it-for/blockchain/img1.svg",
          alt: "placeholder alt",
        },
        links: [
          { copy: "Get in touch", to: "https://trustshare.co" },
          {
            copy: "Developer Centre",
            to: "https://developers.paybase.io/recipes/blockchain",
            color: "white",
            bg: "black",
            shadow: "white",
            hoverColor: "black",
          },
        ],
      }}
      options={{
        titleColor: "blue",
        copyColor: "grey",
        backgroundColor: "lightblue",
        padding: "3rem",
      }}
    />
    <Title copy="Using Paybase’s capabilities for your Blockchain Business?" />
    <TextWithImage
      data={{
        title: "Funds Custody & Regulatory Cover_",
        content:
          "Paybase is an FCA-regulated financial institution. Our Electronic Money Licence authorises us to hold our clients’ and their users’ fiat funds safely in a segregated client-safeguarded account. We’ll cover your regulatory needs, relieving you from having to manoeuvre compliance hurdles and giving you the space to focus on your all-important product innovation.",
        image: {
          src: "/who-is-it-for/blockchain/img2.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#regulatory" }],
      }}
      options={{ titleColor: "darkgreen" }}
    />
    <TextWithImage
      data={{
        title: "Real-time Settlement_",
        content:
          "Make instant transfers into fiat accounts and see the funds reflected in less than 15 seconds. The Paybase solution is API-based, utilising Faster Payments to move user funds. Unlike many providers who reconcile manually, Paybase handles ledgering end-to-end, separating funds into individual accounts for each user. This approach not only enhances security, speed and efficiency but it encourages new and established traders to join your platform, enabling you to stand out from your competition.",
        image: {
          src: "/who-is-it-for/blockchain/img3.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer" }],
      }}
      options={{ isTextRight: true, titleColor: "rose" }}
    />
    <TextWithImage
      data={{
        title: "Agile Due Diligence_",
        content:
          "While most providers offer an off-the-shelf solution with a single set of transactional parameters, Paybase takes a more dynamic approach. If, for instance, you are a cryptocurrency exchange that enables users to buy and sell digital currencies, you might want to vary the level of Due Diligence performed on a user-by-user basis. We assign roles to distinguish between a client’s high net value and standard users - e.g. retail traders vs. corporate traders - and customise end-user limits based on their risk profile.",
        animation: "/animations/engines/onboarding-engine.json",
        links: [{ copy: "Find out more", to: "/what-we-offer#duedilligence" }],
      }}
      options={{ titleColor: "blue" }}
    />
    <FixedBanner />
  </Layout>
)

export default Blockchain
